@media (prefers-color-scheme: dark) {
  .anchor {
    background-color: var(--gray-b);
  }
}

@media (prefers-color-scheme: light) {
  .anchor {
    background-color: var(--gray-a);
  }
}

.anchor {
  color: var(--black-a);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 3px 6px;
}

a.anchor {
  text-decoration: none;
}

.anchor:hover {
  filter: brightness(90%);
  transition: filter .2s ease-in-out;
}

.open {
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  margin-left: 5px;
  line-height: 1.5em;
  display: inline-block;
}
/*# sourceMappingURL=index.cc9304fa.css.map */
