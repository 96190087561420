@media (prefers-color-scheme: dark) {
  .anchor {
    background-color: var(--gray-b);
  }
}

@media (prefers-color-scheme: light) {
  .anchor {
    background-color: var(--gray-a);
  }
}

.anchor {
  padding-left: 6px;
  padding-right: 6px;

  padding-top: 3px;
  padding-bottom: 3px;

  border-radius: 10px;

  margin-left: auto;
  margin-right: auto;

  color: var(--black-a);
}

a.anchor {
  text-decoration: none;
}

.anchor:hover {
  filter: brightness(90%);

  transition: filter 200ms ease-in-out;
}

.open {
  margin-left: 5px;

  line-height: 1.5em;
  width: 1.5em;
  height: 1.5em;

  display: inline-block;

  vertical-align: middle;
}
